@tailwind base;
@tailwind components;
@tailwind utilities;

.card {
  @apply transform transition duration-300;
}

.card:not(.no-hover):hover {
  @apply scale-105;
}

.card-body {
  padding: 0;
}

.card-title {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 700; 
}

.text-base {
  font-family: 'Rajdhani', sans-serif;
}

.modal {
  font-family: 'Rajdhani', sans-serif;
}

.rajdhani-font {
  font-family: 'Rajdhani', sans-serif;
}